import { IconButton } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useRef } from "react";

export default function TextInput({
	type = "text",
	name,
	value,
	className,
	autoComplete,
	required,
	isFocused,
	handleChange,
	placeholder,
	iconRight,
}) {
	const input = useRef();

	useEffect(() => {
		if (isFocused) {
			input.current.focus();
		}
	}, [isFocused]);

	return (
		<div className="relative">
			<input
				type={type}
				name={name}
				value={value}
				className={classNames(
					"border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm",
					className,
				)}
				ref={input}
				autoComplete={autoComplete}
				required={required}
				onChange={(e) => handleChange(e)}
				placeholder={placeholder}
			/>
			{iconRight && (
				<div className="absolute right-4 top-1/2 transform -translate-y-1/2">
					<IconButton onClick={iconRight.onClick} edge="end" size="small">
						{iconRight.icon}
					</IconButton>
				</div>
			)}
		</div>
	);
}
